import { render, staticRenderFns } from "./school.vue?vue&type=template&id=7960892f&scoped=true"
import script from "./school.vue?vue&type=script&lang=js"
export * from "./school.vue?vue&type=script&lang=js"
import style0 from "./school.vue?vue&type=style&index=0&id=7960892f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7960892f",
  null
  
)

export default component.exports